<template>
  <AppTopNav></AppTopNav>
  <AppLayoutCommon>
    <div class="order-info">
      <Alert type="warning" show-icon class="alert">
        请认真核对双方交易内容及其相关的附件资料，如经确认，即表示您已同意详情中的相关信息
      </Alert>
      <h3 style="margin-bottom: 20px">收银台</h3>

      <div class="info-box">
        <dl>
          <dt>
            <span style="color:#1C1F23;font-size: 14px;">支付金额 (元)</span>
          </dt>
					<dt>
             <span style="	color:#D12B23;font-size:32px;;">8888</span>
          </dt>
        </dl>


        <br />
      </div>
      <div class="title">
        <span class="icon"></span>
        <span class="text">支付方式</span>
      </div>
      <div class="info-box-noborder">
        <dl>
          <dt>
            <Radio v-model="single">
              <span style="color: #409eff">账户余额</span>
            </Radio>
          </dt>
          <dd></dd>
        </dl>
        <dl>
          <dt>
            <span
              style="
                margin-left: 4px;
                color: #1c1f23;
                font-size: 18px;
                font-weight: 550;
              "
            >
              ￥688.00
            </span>
          </dt>
          <dd>
            <div class="desc">
              <!-- <span class="icon"></span> -->
							<img :src="require('/static/icons/recharge.png')" />
              <span class="text">充值</span>
            </div>
          </dd>
        </dl>
      </div>
      <Button type="primary" class="btn">确认支付</Button>
    </div>
  </AppLayoutCommon>
</template>

<script>
import AppLayoutCommon from '@/components/AppLayoutCommon'
import AppTopNav from '@/components/AppTopNav'

export default {
  name: 'Checkout',
  data () {
    return {
      single: true,
    }
  },
  setup () {
    return {}
  },
  created () {},
  components: {
    AppLayoutCommon,
    AppTopNav,
  },
  methods: {},
}
</script>

<style scoped lang="less">
.order-info {
  padding: 0 10px;
  // h3 {
  //   font-weight: normal;
  //   font-size: 16px;
  //   padding: 25px 5px;
  // }
  .title {
    height: 20px;
    line-height: 1;
    margin-bottom: 10px;
    padding: 25px 5px;
    .icon {
      display: inline-block;
      height: 20px;
      width: 5px;
      background-color: #d12b23;
      margin-right: 10px;
      vertical-align: middle;
    }
    .text {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .desc {
    margin-left: 20px;
  }
}

// 信息盒子
.info-box {
  border: 1px solid #dcdfe6;
  padding: 10px;
  margin-bottom: 10px;
  height: 155px;
  background: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-box-noborder {
  padding: 10px;
  margin-bottom: 10px;
  dl {
    display: flex;
    line-height: 30px;
    dt {
      width: 80px;
      color: #999;
      font-size: 14px;
      i {
        display: inline-block;
        width: 0.5em;
      }
    }
    dd {
      flex: 1;
    }
  }
  .decs-img-text {
    font-size: 12px;
    color: #909399;
  }
  .down-img-text {
    font-size: 14px;
    color: #409eff;
  }
}
// 汇总信息
.sum-info {
  // padding: 0 30px 10px 30px;
  overflow: hidden;

  .hint {
    float: right;
    text-align: center;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: #dc9100;
  }
  .btn {
    float: right;
    width: 134px;
    background: #d12b23;
    height: 37px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border: 1px solid #cfcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info {
    width: 300px;
    // height: 160px;
    float: right;
    font-size: 14px;

    .item {
      line-height: 40px;
      display: flex;
      label {
        text-align: right;
        width: 160px;
        // font-size: 14px;
        i {
          display: inline-block;
          width: 2em;
        }
      }
      span {
        flex: 1;
        text-align: right;
        padding-right: 30px;
        &.price {
          font-size: 20px;
          color: @priceColor;
        }
      }
    }
  }
}
.btn {
  // display: block;
  width: 144px;
  background: #d12b23;
  height: 37px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: 1px solid #cfcdcd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert {
  margin-top: 10px;
  color: #dc9100;
  font-size: 13px;
  height: 52px;
  display: flex;
  align-items: center;

  // justify-content: center;
}

/deep/ .ivu-alert-icon {
  margin-top: 10px;
}

</style>
